import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import { Link } from '@reach/router'

const NotFoundPage = () => (
  <Layout>
    <Seo title="Fehler" />
    <h1>Nichts gefunden</h1>
    <p>
      Zu dieser Adresse gibt es leider keinen Inhalt. Zurück zur{' '}
      <Link to="/">Startseite</Link>
    </p>
  </Layout>
)

export default NotFoundPage
